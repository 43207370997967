<template>
  <div class="search-bar p-50">
    <b-row class="d-flex justify-content-between align-items-center" noGutters>
      <b-col class="flex-grow-1" id="searchOnboarding">
        <b-input-group label-for="searchInput">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" size="20"/>
          </b-input-group-prepend>
          <b-form-input
              id="searchInput"
              v-model="searchText"
              placeholder="Recherche"
              @input="updateSearch"/>
        </b-input-group>
      </b-col>
      <b-col v-if="filter" class="max-height d-flex justify-content-center flex-grow-0 pl-50">
        <b-button
            class="btn-icon rounded-circle"
            variant="outline-primary"
            id="searchFilterParent"
            @click="filterClick">
          <feather-icon icon="FilterIcon"/>
        </b-button>
      </b-col>
      <b-col v-if="check" class="swiper-no-swiping text-end flex-grow-0 pl-50">
        <b-form-checkbox
            v-model="localFilter"
            class="custom-control-primary"
            name="check-button"
            switch
            @input="filterLocal">
          <span class="switch-icon-left">
            <feather-icon icon="MapPinIcon"/>
          </span>
          <span class="switch-icon-right slash-containter">
            <span class="slash-through">\</span>
            <feather-icon icon="MapPinIcon"/>
          </span>
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow
} from 'bootstrap-vue'

export default {
  name: 'SearchBar',
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      searchText: "",
      localFilter: false
    }
  },
  props: {
    filter: {
      type: Boolean,
      default: false
    },
    check: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    filterClick() {
      this.$emit('filter-clicked')
    },
    filterLocal() {
      this.$emit('local-only', this.localFilter)
    },
    updateSearch(searchText) {
      setTimeout(() => {
        // This timeout and check is used to avoid flooding the updateSearch method when a user writes quickly
        if (this.searchText == searchText) {
          this.$emit('update-search', searchText)
        }
      }, 300);
    },
  },
}
</script>

<style lang="scss">
.search-bar {
  width: 100%;
  padding-bottom: 1rem;

  .input-group {
    background: #FFF;
    border-radius: 0.357rem;
  }

  .search-input {
    margin-top: 5px;
  }

  .max-height {
    max-height: 100px !important;
  }

  .filter {
    top: 3px !important;
  }

  .slash-containter {
    position: relative;

    .slash-through {
      position: absolute;
      color: #5e5873;
      font-weight: 500;
      font-size: 15px;
      left: 4px;
      top: -1px;
    }
  }
}
</style>
